.footer {
    background: linear-gradient(to bottom, #2c2c2c, #000000);
    padding: 1rem 0;
    box-shadow: 0 -2px 4px rgba(255, 255, 255, 0.1);
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.copyright {
    color: #e0e0e0;
    font-size: 0.875rem;
}

.rightSection {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    font-size: 0.875rem;
    text-decoration: none;
    transition: background-color 0.3s ease;
    height: 36px; /* Fixed height for both buttons */
    white-space: nowrap; /* Prevent text wrapping */
    color: #e0e0e0;
    border: 1px solid #e0e0e0;
    background-color: transparent;
}

.button:first-child {
    background-color: #7B68EE;
    color: white;
    border: none;
}

.button:first-child:hover {
    background-color: #6A5ACD;
}

.twitterButton {
    background-color: #1DA1F2;
    color: white;
    border: none;
}

.twitterButton:hover {
    background-color: #1a91da;
}

.twitterIcon {
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
}

@media (max-width: 768px) {
    .container {
        flex-direction: column;
        align-items: center;
        gap: 1rem;
    }

    .rightSection {
        flex-direction: column;
        align-items: stretch;
        width: 100%;
    }

    .button {
        width: 100%;
        justify-content: center;
    }
}
