.navbar {
  background: linear-gradient(to bottom, #2c2c2c, #1a1a1a);
  padding: 1rem 0;
  box-shadow: 0 2px 4px rgba(255, 255, 255, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.navContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.logo {
  color: #ffffff;
  font-weight: bold;
  font-size: 1.5rem;
  text-decoration: none;
}

.navMenu {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navMenu li {
  margin-left: 1.5rem;
}

.navMenu a {
  color: #e0e0e0;
  text-decoration: none;
  transition: color 0.3s ease;
}

.navMenu a:hover {
  color: #ffffff;
}

.menuIcon {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.menuIcon span {
  height: 3px;
  width: 25px;
  background-color: #ffffff;
  margin-bottom: 4px;
  border-radius: 2px;
  transition: all 0.3s ease;
}

@media (max-width: 768px) {
  .menuIcon {
    display: flex;
  }

  .navMenu {
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    flex-direction: column;
    background: linear-gradient(to bottom, #2c2c2c, #1a1a1a);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: none;
  }

  .navMenu.active {
    display: flex;
  }

  .navMenu li {
    margin: 0;
    text-align: center;
  }

  .navMenu a {
    display: block;
    padding: 1rem;
  }
}
