.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup {
  background: linear-gradient(to bottom, #2c2c2c, #1a1a1a);
  padding: 2rem;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
  position: relative;
  box-shadow: 0 4px 6px rgba(255, 255, 255, 0.1);
}

.popup h2 {
  margin-top: 0;
  color: #3090ff;
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
}

.popup form {
  display: flex;
  flex-direction: column;
}

.popup input {
  margin-bottom: 1rem;
  padding: 0.75rem;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  color: #e0e0e0;
}

.popup input::placeholder {
  color: #a0a0a0;
}

.popup button {
  background-color: #3090ff;
  color: white;
  border: none;
  padding: 0.75rem 1rem;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: bold;
}

.popup button:hover {
  background-color: #1a7ad9;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #e0e0e0;
}

.closeButton:hover {
  color: #ffffff;
}
