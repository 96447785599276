.home {
  text-align: center;
  padding: 2rem 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  width: 802px; /* Exact width: 2 * 400px (feature width) + 2px (gap) */
  max-width: 100%;
  padding: 0 1rem;
  box-sizing: border-box;
}

.home h1 {
  font-size: 2.5rem;
  color: #ffffff;
  margin-bottom: 4rem;
  margin-top: 8rem;
  max-width: 90%; /* Adjust this value to make the title narrower */
  margin-left: auto;
  margin-right: auto;
}

.home h1 .highlight {
  color: #c0ddff; /* This is the blue color we've been using */
}

.home h2 {
  font-size: 2.25rem;
  color: #e0e0e0;
  margin-bottom: 3rem;
  margin-top: 4rem; /* Reduced from 8rem to bring content closer together */
}

.features {
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
}

.feature {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(255, 255, 255, 0.1);
  width: calc(50% - 1rem);
  max-width: 400px;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 250px; /* Fixed height for both boxes */
  box-sizing: border-box;
}

.featureContent {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.feature h3 {
  color: #60aaff; /* Lighter shade of medium slate blue */
  margin-bottom: 1rem;
}

.feature p {
  color: #e0e0e0;
  flex-grow: 1; /* Allow paragraph to grow and push button to bottom */
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  display: inline-block;
  background-color: #60aaff; /* Updated to medium slate blue */
  color: #FFFFFF;
  padding: 0.5rem 1rem;
  border-radius: 20px; /* Rounded corners */
  text-decoration: none;
  transition: background-color 0.3s ease;
  align-self: center; /* Center the button horizontally */
  margin-top: auto; /* Push button to the bottom */
}

.button:hover {
  background-color: #1d4363; /* Lighter shade for hover */
}

@media (max-width: 840px) {
  .content {
    width: 100%;
  }

  .feature {
    width: 100%;
    max-width: none;
  }
}
